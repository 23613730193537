<template>
  <v-container class="mt-10 my-2 mx-2 px-2">
    <v-card class="pa-5">
      <h3 class="text-center">{{ $t("profile.change") }}</h3>
      <hr class="ma-3" />
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            :label="$t('profile.pass')"
            :placeholder="$t('profile.pass')"
            filled
            rounded
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="passwordRules"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              @click:append="show1 = !show1"
            v-model="pass"
            prepend-icon="mdi-key-outline"
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            :label="$t('profile.rpass')"
            :placeholder="$t('profile.rpass')"
            filled
            rounded
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="passwordRules"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              @click:append="show1 = !show1"
            v-model="rpass"
            prepend-icon="mdi-key-outline"
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-col cols="10" sm="10" md="12" class="text-center">
        <v-btn rounded color="success" @click="changrPass" :loading="loading">
          <v-icon>mdi-update</v-icon> {{ $t("profile.change") }}
        </v-btn>
      </v-col>
    </v-card>
        <v-card class="pa-3">
      {{ $t("dash.lognin") }}
      <timeago
        class="float-center grren--text"
        :datetime="this.$store.getters.getupdateAt"
        :auto-update="60"
      ></timeago>
      {{ $t("dash.inday") }}
      <strong class="red--text">{{ this.$store.getters.getupdateAt }}</strong>
    </v-card>
    <v-divider></v-divider>
    <v-divider></v-divider>
    <v-card class="pa-3">
      <h2>
        <v-icon>mdi-information-outline</v-icon> {{ $t("dash.infouser") }}:
        <v-chip
          close-icon="mdi-delete"
          color="yellow"
          filter
          label
          link
          class="black--text"
          >id {{ username }} :
          {{ iduser }}
        </v-chip>
      </h2>
      <div>
        <br />
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              :label="$t('signup.form.username')"
              placeholder="username"
              filled
              rounded
              v-model="username"
              readonly
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              :label="$t('signup.form.email')"
              placeholder="email"
              filled
              v-model="email"
              rounded
              readonly
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <div>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              :label="$t('signup.form.country')"
              placeholder="country"
              filled
              rounded
              v-model="country"
              readonly
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              label="member"
              placeholder="member"
              filled
              v-model="member"
              rounded
              readonly
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <div>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <div
              class="pt-4 pl-2"
              style="border-radius:15px;width:100%;background-color:#424242;height:50px;font-size:11px"
            >
              <strong class="grey--text">date create account:</strong>
              <timeago
                class=" pa-2"
                :datetime="datec"
                :auto-update="60"
              ></timeago>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              label="last update"
              placeholder="last update"
              filled
              rounded
              readonly
              dense
            >
              <timeago
                class="float-center"
                :datetime="last"
                :auto-update="60"
              ></timeago
            ></v-text-field>
          </v-col>
        </v-row>
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            label="token"
            placeholder="token"
            filled
            rounded
            dense
            v-model="token"
          >
          </v-text-field>
          <v-btn
            class="float-right copybott"
            rounded
            fab
            color="success"
            @click="cpToken(token)"
            ><v-icon>mdi-clipboard-check-outline</v-icon>
          </v-btn>
       
        </v-col>
      </div>
    </v-card>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      pass: "",
      rpass: "",
      show1: false,
       username: this.$store.getters.getUserName,
      email: this.$store.getters.getEmail,
      country: this.$store.getters.getCountry,
      datec: this.$store.getters.getcreatedAt,
      last: this.$store.getters.getupdateAt,
      member: "free account",
      token: `https://sp0mers-team.com/user?${this.$store.getters.getUserName}`,
      iduser: this.$store.getters.getUserId,
     
    };
  },
  methods: {
    changrPass: function () {
      if (this.pass == "") {
      this.$toastr("add", {
              title: "Opps",
              msg: this.$t('signup.errors.prequired'),
              timeout: 3000,
              position: "toast-top-center",
              type: "error",
              closeOnHover: true,
              clickClose: false,
            });
      } else if (this.pass != this.rpass) {
       
         this.$toastr("add", {
              title: "Opps",
              msg: this.$t('signup.errors.invalidp'),
              timeout: 3000,
              position: "toast-top-center",
              type: "error",
              closeOnHover: true,
              clickClose: false,
            });
      } else {
        let AuthStr = "Bearer ".concat(this.$store.getters.getToken);
        this.$http
          .get(
            "/auth/changepass",

            {
              headers: {
                Authorization: AuthStr,
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                id: this.$store.getters.getUserName,
                password: this.pass,
              },
            }
          )
          .then((res) => {
           this.$toastr("add", {
              title: "Opps",
              msg: res.data.message,
              timeout: 3000,
              position: "toast-top-center",
              type: "success",
              closeOnHover: true,
              clickClose: false,
            });
           
          })
          .catch(({ response }) => {
            // alert(response?.data?.error?.message);

            this.$toastr("add", {
              title: "Opps",
              msg: response?.data?.error?.message,
              timeout: 3000,
              position: "toast-top-center",
              type: "warning",
              closeOnHover: true,
              clickClose: false,
            });
          });
      }
    },
  },
};
</script>